<template>
  <div>
    <!-- 上传 -->
    <input class="up-box" :id="id" type="file" title="" ref="upload" :multiple="maxNumNew" :accept="isCheckTypeFilter()" @change="handleChangeFile">
  </div>
</template>

<script>
import OSS from 'ali-oss'
import { OSSURL, FILES_TYPE } from '@/hooks/ali-oss'
import { message } from 'ant-design-vue'
import { getOssToken } from '@/api/index'
export default {
  props: {
    upObj: {
      type: Array,
      default: () => {
        return []
      }
    }, // 上传时分验证对象（类型，大小，等）,多选验证时为数组
    oldList: {
      type: Array,
      default: () => {
        return []
      }
    }, // 传入进来的文件列表，（编辑时，编辑后的默认列表）
    maxNum: {}, // 文件最大数
    filter: {}, // 文件验证规则
    isMore: {}, // 是否开启多类型检测
    id: {
      type: String,
      default: 'file'
    },
    isAuto: {
      type: Boolean,
      default: false
    }, // 是否自动上传，默认不自动上传
    callBack: {}, // 自动上传的回调函数
    point: {
      type: Boolean,
      default: false
    } // 每个文件进度条
  },
  data () {
    return {
      fileList: [], // 选择的文件列表
      list: this.oldList,
      ossConfig: {}, // 阿里云签名对象
      loading: null,
      checkpoint: 0, // 记录上传进度
      checkpointOld: 0,
      OSSURL,
      totalSize: 0, // 文件总大小
      nowSize: 0,
      FILES_TYPE,
      isOnce: true
      // filterRuls: this.filter || this.upObj[0].filter // 多种大类型上传必传filter
    }
  },
  computed: {
    filterRuls () {
      if (this.filter) return this.filter
      if (this.upObj.length > 0) return this.upObj[0].filter
      return ''
    },
    maxNumNew () {
      if (this.maxNum) return this.maxNum
      if (this.upObj.length > 0) return this.upObj[0].maxNum
      return false
    }
  },
  methods: {
    isCheckTypeFilter () {
      if (!this.filterRuls) return ''
      let _type = this.filterRuls.split('.')
      _type.shift()
      _type = _type.map(item => '.' + item)
      return _type.toString()
    },
    clearFile () {
      this.$refs.upload.value = ''
    },
    checkMaxCall (maxNum) {
      message.error(`最多上传${maxNum}个文件`)
      this.clearFile()
    },
    // 验证文件个数
    checkFileNum (maxNum) {
      if (this.list.length === 0) {
        if (this.fileList.length > maxNum) {
          this.checkMaxCall(maxNum)
          return false
        }
      } else {
        if (this.fileList.length > (maxNum - this.list.length)) {
          this.checkMaxCall(maxNum)
          return false
        }
      }
      return true
    },
    // 选择文件
    handleChangeFile () {
      this.fileList = this.$refs.upload.files
      let file = ''

      if (this.maxNum !== undefined) {
        if (!this.checkFileNum(this.maxNum)) return false
      } else {
        // 文件个数限制
        if (!this.checkFileNum(this.upObj[0].maxNum)) return false
      }

      for (let i = 0; i < this.fileList.length; i++) {
        file = this.fileList[i]

        // 文件名长度验证
        if (!this.checkFileNameLength(file)) return false

        // 文件重命名
        const _fileNameObj = this.resetFileName(file)
        const _suffix = _fileNameObj.suffix // 后缀
        const _fileName = _fileNameObj.newName // 重命名的名字

        file.suffix = _suffix

        // 文件类型验证
        if (!this.checkFileType(file, this.fileList, _suffix)) return false
        // 文件大小验证
        if (!this.checkFileSize(file, this.fileList)) return false

        file.bunket = this.setUploadCatalog(_fileName, _suffix)
        file.http = this.OSSURL + '/' + file.bunket
        file.file = file
      }
      this.previewViewData(this.fileList)
    },
    // 文件重命名
    resetFileName (file) {
      const _fileName = file.name.split('.')
      const _suffix = _fileName.pop()
      const _fileObj = {
        suffix: _suffix,
        newName: file.name
      }
      if (!file.realName) {
        file.realName = _fileName.join('.') + '_' + Date.now() + '.' + _suffix
        _fileObj.newName = file.realName
      }
      return _fileObj
    },
    // 验证文件类型
    checkFileType (file, fileList, suffix) {
      if (this.isMore) {
        if (this.filterRuls.indexOf(suffix) === -1) {
          this.clearFile()
          message.error(`只支持${this.filterRuls}的文件格式`)
          return false
        }
      } else {
        if (this.upObj[0].filter && this.upObj[0].filter.indexOf(suffix) === -1) {
          this.clearFile()
          message.error(`只支持${this.upObj[0].filter}的文件格式`)
          return false
        }
      }
      return true
    },
    // 验证文件大小的回调
    checkFileCall (size, file) {
      const _size = size * 1024 * 1024
      if (file.size > _size) {
        this.clearFile()
        message.error(`单个上传文件大小不超过${size}M`)
        return false
      }
      return true
    },
    // 验证文件大小
    checkFileSize (file) {
      const _size = this.checkType(file.suffix).size
      if (_size !== undefined) {
        if (!this.checkFileCall(_size, file)) return false
      }
      return true
    },
    // 验证文件名长度
    checkFileNameLength (file) {
      if (file.name.length > 50) {
        this.clearFile()
        message.error('文件名长度不能大于50个字符')
        return false
      }
      return true
    },
    // 根据类型做文件大小验证
    checkType (suffix) {
      return this.upObj.filter(item => item.filter.indexOf(suffix) !== -1)[0]
    },
    // 设置上传目录
    setUploadCatalog (fileName, suffix) {
      try {
        // let _catalog = this.upObj.bunket + '/' + fileName
        const _catalog = this.checkType(suffix).bunket + '/' + fileName
        return _catalog
      } catch (error) {
        message.error('bucket字段为必传项（bucket：包地址）')
        return false
      }
    },
    // 预览
    previewViewData (fileList) {
      const $vm = this
      fileList.forEach((item) => {
        const reader = new FileReader()
        reader.readAsDataURL(item)
        reader.onloadend = (ev) => {
          const _obj = {
            file_name: item.name,
            file_path: item.bunket,
            url: ev.target.result,
            file: item,
            file_type: $vm.setFileType(item),
            file_size: $vm.setFileSize(item.size),
            add_time: $vm.getDate()
          }
          if ($vm.point) {
            $vm.list.push(Object.assign(_obj, {
              point: 0
            }))
          } else {
            $vm.list.push(_obj)
          }
          const _list = $vm.list.filter(item => Object.hasOwnProperty.call(item, 'url'))
          if (_list.length === fileList.length) {
            $vm.$emit('changeFile', $vm.list)
            // 如果设置了自动上传了，直接调上传
            if (this.isAuto) {
              this.fetchSend(this.callBack)
            }
          }
        }
      })
    },
    getDate () {
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const hour = date.getHours()
      const minute = date.getMinutes()
      const second = date.getSeconds()
      const time = year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second)
      return time
    },
    addZero (s) {
      return s < 10 ? ('0' + s) : s
    },
    // 文件类型转换
    setFileType (item) {
      const _item = this.FILES_TYPE.filter(items => items.value.indexOf(item.suffix) >= 0)
      if (_item.length) {
        return _item[0].type
      }
      return ''
    },
    // 换算size单位 求次幂
    pow1024 (num) {
      return Math.pow(1024, num)
    },
    // 文件大小转换
    setFileSize (size) {
      if (!size) return 0 + 'b'
      return size < 1024 ? size + ' B' : size < this.pow1024(2) ? (size / 1024).toFixed(2) + ' KB' : size < this.pow1024(3) ? (size / this.pow1024(2)).toFixed(2) + ' MB' : size < this.pow1024(4) ? (size / this.pow1024(3)).toFixed(2) + ' GB' : (size / this.pow1024(4)).toFixed(2) + ' TB'
    },
    // 获取oss
    getOss () {
      return getOssToken()
    },
    // 上传阿里云
    handleHttpRequest (fileData, fileObj) {
      const _size = 1 * 1024 * 1024 // 最小片段为1M
      const { bunket, file } = fileData
      let client = new OSS(this.ossConfig)
      // 分片上传
      if (fileData.size > _size) {
        return client.multipartUpload(bunket, file, {
          progress: (p, checkpoint) => {
            if (p === 1) {
              if (Object.hasOwnProperty.call(fileObj, 'point')) {
                fileObj.point = Math.floor(p * 100)
              }
              return false
            }
            if (Object.hasOwnProperty.call(fileObj, 'point')) {
              fileObj.point = Math.floor(p * 100)
              console.log(fileObj.point)
            } else {
              const isLast = checkpoint.fileSize - (checkpoint.doneParts.length * checkpoint.partSize)
              this.nowSize += Math.min(isLast, checkpoint.partSize)
              this.checkpoint = this.nowSize / this.totalSize * 100
              this.$store.state.loadingObj.tip = `上传中,请稍后,当前进度${Math.floor(this.checkpoint)}%`
            }
          }
        }).then(res => {
          this.$store.state.loading = false
          this.$store.state.loadingObj.tip = ''
          client = null
          return res
        }).catch(error => {
          if (Object.hasOwnProperty.call(fileObj, 'point')) {
            fileObj.state = true
          }
          this.$store.state.loading = false
          this.$store.state.loadingObj.tip = ''
          console.log(error)
          return Promise.reject(error)
        })
      } else {
        // 直接上传
        return client.put(bunket, file).then(res => {
          if (Object.hasOwnProperty.call(fileObj, 'point')) {
            fileObj.point = 100
          }
          this.$store.state.loading = false
          this.$store.state.loadingObj.tip = ''
          client = null
          return res
        }).catch(error => {
          if (Object.hasOwnProperty.call(fileObj, 'point')) {
            fileObj.state = true
          }
          console.log(error)
          this.$store.state.loading = false
          this.$store.state.loadingObj.tip = ''
          return Promise.reject(error)
        })
      }
    },
    /**
     *上传
     *
     * @param {function} getOss 获取阿里云签名
     * @param {function} callBack 上传之后的回调
     */
    async fetchSend (callBack, getOss = this.getOss) {
      const _http = []
      // 获取签名
      const ossRes = await getOss()
      if (ossRes.success) {
        this.ossConfig = {
          accessKeyId: ossRes.data.accessKeyId,
          accessKeySecret: ossRes.data.accessKeySecret,
          stsToken: ossRes.data.securityToken,
          bucket: ossRes.data.bucket,
          region: 'oss-cn-chengdu'
        }
      } else {
        message.error('验证阿里云签名错误')
        return false
      }
      this.$store.state.loading = true
      this.$store.state.loadingObj.tip = '上传中，请稍后...'
      // 筛选
      // eslint-disable-next-line no-prototype-builtins
      const _list = this.list.filter(item => item.hasOwnProperty('url'))

      // 上传
      _list.forEach((item, index) => {
        this.totalSize += item.file.size
        _http.push(this.handleHttpRequest(item.file, item))
      })
      return Promise.all(_http).then(() => {
        callBack && callBack()
        if (this.isAuto) {
          this.$emit('autoCallback', this.list.map(item => {
            if (!Object.hasOwnProperty.call(item, 'file_path')) {
              return item
            } else {
              return item.file_path
            }
          }))
        }
        this.list = []
        this.$store.state.isShow = false
        return Promise.resolve()
      }).catch((error) => {
        message.error('上传失败,请重试')
        this.nowSize = 0
        this.totalSize = 0
        this.$store.state.isShow = false
        return Promise.reject(new Error(error))
      })
    },
    // 清空选择文件列表
    clearFiles () {
      this.list = []
      this.$emit('changeFile', this.list)
    },
    // oss删除文件
    async delOss (list, getOss = this.getOss) {
      // list -- 要删除的文件列表名-['a.jpg','b.mp3']
      // 获取签名
      const ossRes = await getOss()
      if (ossRes.success) {
        this.ossConfig = {
          accessKeyId: ossRes.data.accessKeyId,
          accessKeySecret: ossRes.data.accessKeySecret,
          stsToken: ossRes.data.securityToken,
          bucket: ossRes.data.bucket,
          region: 'oss-cn-chengdu'
        }
      } else {
        message.error('验证阿里云签名错误')
        return false
      }

      try {
        const client = new OSS(this.ossConfig)
        // 批量删除阿里云上面的文件后台没有开放权限，弃用
        // return await client.deleteMulti(list, {
        //   quiet: true
        // })
        list.forEach(item => {
          client.delete(item)
        })
      } catch (error) {
        console.log('阿里云删除资源出错了')
      }
    }
  },
  watch: {
    upObj: {
      deep: true,
      handler (newObj) {
        // eslint-disable-next-line vue/no-mutating-props
        // this.upObj = newObj
      }
    },
    oldList: {
      handler (newObj) {
        this.list = newObj
        // eslint-disable-next-line no-prototype-builtins
        // if (this.isOnce && newObj.length > 0 && !newObj[0].hasOwnProperty('file')) {
        //   this.isOnce = false
        //   this.list = JSON.parse(JSON.stringify(newObj))
        //   this.$emit('changeFile', this.list)
        // }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#file{
  visibility: hidden;
}
.up-box{
  visibility: hidden;
  width: 0;
}
.ant-form input[type='file'] {
    display: none;
}
</style>
